<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="dailyb">
        <div class="tit">
            <div>
                <p v-if="!shifou_geren">{{xuanze_leixing(dangqian_num)}}
                    <i class="el-icon-caret-left"></i>
                    <i class="el-icon-caret-bottom"></i>
                </p>
                <ul v-if="!shifou_geren">
                    <li v-for="i in leixing" :key="i.id" :class="dangqian_num==i.id?'lanse':''" @click="dangqian_num=i.id">{{i.label}}</li>
                </ul>
            </div>
            {{wenben}}日报
            <p>
                <!-- 全屏 -->
            </p>
        </div>
        <div class="tit_2">
            <!-- <p></p> -->
            <ul>
                <li @click="dianji_xuanze(index)" v-for='(i,index) in biao' :key='index' :style="xianzai_xuanzhong==index?'background:#F96C6C;border:0.01rem solid red;color:#fff':''">{{i}}</li>
            </ul>
            <div v-if="!shifou_geren" class="shaixuan">筛选{{xuanze_leixing(dangqian_num)}}
                <el-tooltip class="item" effect="dark" :content="shaixuan_name" placement="top">
                    <p @click="xuanze_zhanshi_jiekou(dangqian_num)">{{shaixuan_name}}</p>
                </el-tooltip>
            </div>
        </div>
        <div v-if="!shiyou_shuju" id="rbao2" :style="{width: '100%', height: '70%'}"></div>
        <img v-if="shiyou_shuju" class="shili_tu" src="../../assets/images/rbao_shili1.png" alt="">
        <ul class="shi_li">
            <li v-for="(i,index) in shili" :key='index'><span :style="i.color"></span>{{i.name}}</li>
        </ul>
        <div class="beijing" v-if="nianfen_xianshi_yincang">
            <div class="box1">
                <p class="tit_box">{{wenben}}日报</p>
                <p class="con">请选择{{wenben}}日报的年份</p>
                <div class="con_box">
                    选择月份&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-date-picker
                        v-model="nian_sj"
                        type="month"
                        placeholder="选择月">
                    </el-date-picker>
                </div>
                <div class="foot">
                    <span @click="xuanze_nianfen_queding">确定</span>
                    <span @click="nianfen_xianshi_yincang=false">取消</span>
                </div>
            </div>
        </div>
        <el-dialog title="选择部门" :center="true" :show-close='false' append-to-body :visible.sync="bumen">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="nei_box">
                        <!-- check-strictly -->
                        <el-tree
                            :data="bumen_tree"
                            show-checkbox
                            default-expand-all
                            ref="tree"
                            highlight-current
                            :props="tree_guize"
                            style="background: #E7E8EB"
                            >
                            <!-- @check='fuxuan' -->
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="bumen_sure">确定</p>
                <p class="quxiao" @click="bumen=false">取消</p>
            </div>
        </el-dialog>
        <el-dialog title="选择产品" :center="true" :show-close='false' append-to-body :visible.sync="xuanze_chanpin">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="nei_box">
                        <!-- check-strictly -->
                        <el-tree
                            :data="chanpin_tree"
                            show-checkbox
                            default-expand-all
                            ref="xiaoshou_chanpin_tree"
                            highlight-current
                            :props="tree_guize"
                            check-strictly
                            style="background: #E7E8EB"
                            >
                            <!-- @check='fuxuan' -->
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="chanpin_sure">确定</p>
                <p class="quxiao" @click="xuanze_chanpin=false">取消</p>
            </div>
        </el-dialog>
        <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="shaixuan_ren">
            <div class="tiao_bumen2">
                <div class="xuanze_left">
                    <p class="tit">选择：</p>
                    <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" v-model="sousuo_text" @keyup='shaixuan_sousuo'>
                        <!--  @keyup="keyu" -->
                    </div>
                    <div class="mianbaoxie">
                        <div v-for="(a,idx) in mianbao_chaosong_list" :key='idx'  @click="mianbao_chaosong_dian(a,idx)">
                            <p :class="mianbao_chaosong_list.length-1==idx?'p1':'p2'">{{a.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="quanxuan" v-if="chaosong_qiye_renyuan_list.length!=0">
                        <img @click="dianji_chaosong_quanxuan" :src="renyuan_chaosong_quanxuan?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                        <span>全选</span>
                    </div>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_bumen_list" :key="idx" @click="dianji_chaosong_bumen(a)">
                                    <!-- <img class="img2" @click.stop='chaosong_bumen(a)' :src="a.zhi?require('../assets/okblue.png'):require('../assets/noccc.png')" alt=""> -->
                                    <p style="margin-left:0.16rem;">{{a.dept_name}}</p>
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_renyuan_list" :key="idx" style='justify-content:left;margin-left:-0.07rem;'>
                                    <img class="img2" @click="dianji_chaosong_xuanren(a)" :src="a.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    <p>{{a.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="xuanze_right">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_renyuan_list_xuan" :key="idx">
                                    <p>{{a.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="chaosong_renyuan_sure">确定</p>
                <p class="quxiao" @click="shaixuan_ren=false;chaosong_renyuan_list_xuan=[]">取消</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { query_all_module_read_power_list, query_user_info, query_prod_tree_sale_rule, get_staff_list_like, query_dept_staff_list, query_ent_dept_list, query_ent_dept_list_aim, get_rec_daily_history, get_inv_daily_history, get_con_daily_history, get_con_daily, get_rec_daily, get_inv_daily } from '../../api/api'
export default {
  name: 'dailyb',
  data () {
    return {
      wenben: '',
      biao: ['顾过去', '看现在', '望未来'],
      shili: [
        {
          name: '目标',
          color: 'background:#FF9C59'
        },
        {
          name: '已完成',
          color: 'background:#73D0EF'
        },
        {
          name: '预测',
          color: 'background:#AB95F2'
        },
        {
          name: '达标',
          color: 'background:#6ABFA7'
        },
        {
          name: '未达标',
          color: 'background:#F16977'
        }
      ],
      xianzai_xuanzhong: '1',
      date_list: [],
      yuan: '元',
      shuzhi: '1',
      mubiao: '',
      sj: '', // 当前时间
      zhuyao: '',
      amt_list_a: [],
      amt_list: [],
      funn_list: [],
      nian_sj: '',
      nianfen_xianshi_yincang: false,
      zhi_zixian: [],
      color: '',
      dangqian_num: '',
      leixing: [],
      shaixuan_name: '',
      shaixuan_neirong: null, // 筛选内容
      bumen: false,
      bumen_tree: [],
      tree_guize: {
        children: 'children',
        label: 'label'
      },
      zhu_guanli_yuan: false,
      zhuguan: false,
      shifou_keshi: false,
      xuanze_chanpin: false,
      chanpin_tree: [],
      path: '',
      sousuo_text: '',
      mianbao_chaosong_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      renyuan_chaosong_quanxuan: false,
      chaosong_renyuan_list_xuan: [],
      chaosong_qiye_renyuan_list: [],
      chaosong_qiye_bumen_list: [],
      shaixuan_ren: false,
      quanbu_kejian: false,
      wenzi_zhanshi_yincang: false,
      wenzi_zhanshi_yincang3: false,
      shiyou_shuju: false,

      shifou_geren: true// 是否是只选择产品  条件  部门主管   子管理员  主管   都不是
    }
  },
  created () {
    // this.wenben=this.$bendi_naqu_cunchu('pc_mlbb_xiaoshou_biao_biaoti','暂时')
    // this.jichu()
    this.quanxian()
    this.mianbao_chaosong_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  mounted () {

  },
  watch: {
    dangqian_num () {
      this.shaixuan_neirong = null
      this.shaixuan_name = ''
      this.jichu(this.wenben)
    },
    shaixuan_ren () {
      if (!this.shaixuan_ren) {
        this.chaosong_renyuan_list_xuan = []
        this.chaosong_qiye_renyuan_list = []
        this.renyuan_chaosong_quanxuan = false
        this.mianbao_chaosong_list = [
          {
            name: JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name,
            dept_id: '0'
          }
        ]
      }
    },
    wenben () {
      this.xianzai_xuanzhong = '1'
    }
  },
  methods: {
    pa (s) {
      return s < 10 ? '0' + s : s
    },
    // 获取本月时间
    jinyue () {
      this.sj = new Date()
      var xian_sj = this.sj
      this.sj =
                xian_sj.getFullYear() + '/' + this.pa(xian_sj.getMonth() + 1) + '/'
      console.log(6666, this.sj)
    },
    jichu (wenben) {
      this.wenben = wenben
      this.jinyue()
      if (this.wenben == '合同') {
        get_con_daily({
          data: {
            ent_id: this.$ent_id(),
            aim_type: this.shifou_geren ? '2' : this.dangqian_num + '',
            staff_id: this.dangqian_num == 2 ? this.shaixuan_neirong : null,
            prod_id: this.dangqian_num == 3 ? this.shaixuan_neirong : null,
            dept_id: this.dangqian_num == 1 ? this.shaixuan_neirong : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          if (res.data.code == 10033) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.date_list = date
            this.mubiao = 'amt_task_aim'
            this.zhuyao = 'con'
            this.color = '#73D0EF'
            this.shiyou_shuju = false
            this.danwei()
          } else if (res.data.code == 10034) {
            // 合成失败
            this.shiyou_shuju = true
          } else if (res.data.code == 10035) {
            // 没有数据
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          } else if (res.data.code == 12200) {
            // 没有目标数据
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          }
        })
      } else if (this.wenben == '回款') {
        get_rec_daily({
          data: {
            ent_id: this.$ent_id(),
            est_type: '1',
            aim_type: this.shifou_geren ? '2' : this.dangqian_num + '',
            staff_id: this.dangqian_num == 2 ? this.shaixuan_neirong : null,
            prod_id: this.dangqian_num == 3 ? this.shaixuan_neirong : null,
            dept_id: this.dangqian_num == 1 ? this.shaixuan_neirong : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10052) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.date_list = date
            this.mubiao = 'amt_aim'
            this.zhuyao = 'rec'
            this.color = '#CAE024'
            this.shiyou_shuju = false
            this.danwei()
          } else if (res.data.code == 10053) {
            // 合成失败
            this.shiyou_shuju = true
          } else if (res.data.code == 10054) {
            // 没有数据
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          } else if (res.data.code == 12200) {
            // 没有目标数据
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          }
        })
      } else if (this.wenben == '发票') {
        get_inv_daily({
          data: {
            ent_id: this.$ent_id(),
            est_type: '1',
            aim_type: this.shifou_geren ? '2' : this.dangqian_num + '',
            staff_id: this.dangqian_num == 2 ? this.shaixuan_neirong : null,
            prod_id: this.dangqian_num == 3 ? this.shaixuan_neirong : null,
            dept_id: this.dangqian_num == 1 ? this.shaixuan_neirong : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          if (res.data.code == 10043) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.date_list = date
            this.mubiao = 'amt_aim'
            this.zhuyao = 'inv'
            this.color = '#5596D4'
            this.shiyou_shuju = false
            this.danwei()
          } else if (res.data.code == 10044) {
            // 合成失败
            this.shiyou_shuju = true
          } else if (res.data.code == 10045) {
            // 没有数据
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          } else if (res.data.code == 12200) {
            // 没有目标数据
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          }
        })
      }
    },
    xuanze_leixing (num) {
      if (num == 1) {
        return '部门'
      } else if (num == 2) {
        return '人员'
      } else if (num == 3) {
        return '产品'
      }
    },
    quanxian () {
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
            this.zhuguan = true
          } else {
            this.zhuguan = false
          }
          if (res.data.body.dept_manager == 1 || res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
            this.shifou_geren = false // 是否是直选产品
            this.leixing = [
              {
                label: '部门',
                id: '1'
              },
              {
                label: '人员',
                id: '2'
              },
              {
                label: '产品',
                id: '3'
              }
            ]
            this.dangqian_num = '1'
          } else {
            this.shifou_geren = true // 是否是直选产品
            this.leixing = [
              {
                label: '产品',
                id: '3'
              }
            ]
            this.dangqian_num = '3'
          }
        }
      })
      query_all_module_read_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            this.quanbu_kejian = true
          } else {
            this.quanbu_kejian = false
          }
        } else if (res.data.code == 500) {}
      })
    },
    // 单位
    danwei () {
      const zongmubiao = this.date_list.amt_aim_sum_list[this.date_list.amt_aim_sum_list.length - 1].toFixed(0) + ''
      console.log(zongmubiao)
      if (zongmubiao.length <= 4) {
        this.yuan = '元'
        this.shuzhi = 1
      } else if (zongmubiao.length <= 8 && zongmubiao.length > 4) {
        this.yuan = '万元'
        this.shuzhi = 10000
      } else if (zongmubiao.length > 8) {
        this.yuan = '亿'
        this.shuzhi = 100000000
      }
      this.date_list.amt_aim_avg = Number((this.date_list.amt_aim_avg / this.shuzhi).toFixed(2))
      this.date_list.amt_aim_left_avg = Number((this.date_list.amt_aim_left_avg / this.shuzhi).toFixed(2))
      this.date_list['amt_' + [this.zhuyao] + '_avg'] = Number((this.date_list['amt_' + [this.zhuyao] + '_avg'] / this.shuzhi).toFixed(2))
      this.date_list['amt_' + [this.zhuyao] + '_est'] = Number((this.date_list['amt_' + [this.zhuyao] + '_est'] / this.shuzhi).toFixed(2))
      this.date_list['amt_' + [this.zhuyao] + '_sum'] = Number((this.date_list['amt_' + [this.zhuyao] + '_sum'] / this.shuzhi).toFixed(2))
      this.date_list[this.mubiao] = Number((this.date_list[this.mubiao] / this.shuzhi).toFixed(2))
      this.date_list.value_y = Number((this.date_list.value_y / this.shuzhi).toFixed(2))
      for (let i = 0; i < this.date_list.amt_aim_sum_list.length; i++) {
        this.date_list.amt_aim_sum_list[i] = Number((this.date_list.amt_aim_sum_list[i] / this.shuzhi).toFixed(2))
      }
      for (let i = 0; i < this.date_list['amt_' + [this.zhuyao] + '_sum_list'].length; i++) {
        this.date_list['amt_' + [this.zhuyao] + '_sum_list'][i]['amt_' + [this.zhuyao] + '_day'] = Number((this.date_list['amt_' + [this.zhuyao] + '_sum_list'][i]['amt_' + [this.zhuyao] + '_day'] / this.shuzhi).toFixed(2))
        this.date_list['amt_' + [this.zhuyao] + '_sum_list'][i]['amt_' + [this.zhuyao] + '_sum'] = Number((this.date_list['amt_' + [this.zhuyao] + '_sum_list'][i]['amt_' + [this.zhuyao] + '_sum'] / this.shuzhi).toFixed(2))
      }
      // 把用到的东西放到一个数值里
      this.amt_list_a = []
      this.amt_list = []
      this.funn_list = []
      const can_list = this.date_list['amt_' + [this.zhuyao] + '_sum_list']
      can_list.map((item, index) => {
        this.amt_list_a.push(
          Object.assign(
            {},
            {
              value: can_list[index]['amt_' + [this.zhuyao] + '_sum'],
              status: parseInt(can_list[index].flag_finish)
            }
          )
        )
        this.amt_list.push(
          Object.assign({}, { value: can_list[index]['amt_' + [this.zhuyao] + '_day'] })
        )
        this.funn_list.push(
          Object.assign({}, { value: can_list[index]['amt_' + [this.zhuyao] + '_sum'] })
        )
      })
      if (this.date_list.amt_funn_sum_list != undefined) {
        for (let i = 0; i < this.date_list.amt_funn_sum_list.length; i++) {
          this.date_list.amt_funn_sum_list[i]['amt_' + [this.zhuyao] + '_est_day'] = Number((this.date_list.amt_funn_sum_list[i]['amt_' + [this.zhuyao] + '_est_day'] / this.shuzhi).toFixed(2))
          this.date_list.amt_funn_sum_list[i]['amt_' + [this.zhuyao] + '_est_sum'] = Number((this.date_list.amt_funn_sum_list[i]['amt_' + [this.zhuyao] + '_est_sum'] / this.shuzhi).toFixed(2))
        }
        this.zhi_zixian = this.date_list.amt_funn_sum_list[this.date_list.amt_funn_sum_list.length - 1]['amt_' + [this.zhuyao] + '_est_sum']
        const funn_list_box = this.date_list.amt_funn_sum_list
        funn_list_box.map((item, index) => {
          this.funn_list.push(
            Object.assign(
              {},
              {
                value: funn_list_box[index]['amt_' + [this.zhuyao] + '_est_sum'],
                status: -1
              }
            )
          )
          this.amt_list_a.push(
            Object.assign(
              {},
              {
                value: can_list[can_list.length - 1]['amt_' + [this.zhuyao] + '_sum'],
                status: -1
              }
            )
          )
          this.amt_list.push(
            Object.assign({}, { value: funn_list_box[index]['amt_' + [this.zhuyao] + '_est_day'] })
          )
        })
      }
      if (this.xianzai_xuanzhong == 1 && !this.nianfen_xianshi_yincang) {
        this.xian()
      }
      this.canver()
    },
    // 第一幅图
    canver () {
      var echarts = require('echarts')
      var myChart = echarts.init(document.getElementById('rbao2'))
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            label: {
              formatter: this.sj + '{value}'
            }
          }
        },
        xAxis: {
          name: '/天',
          boundaryGap: false,
          data: this.date_list.x_axis,
          nameTextStyle: {
            color: '#4B4B4B',
            fontSize: 10
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#eee'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#eee'
            }
          },
          axisLabel: {
            interval: 0,
            fontSize: 10,
            show: true,
            textStyle: {
              color: '#4B4B4B'
            }
          }
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#eee'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#eee'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#4B4B4B'
            }
          }
        },
        series: [
          {
            name: '',
            type: 'bar',
            data: this.amt_list,
            animationDelay: function (idx) {
              return idx * 60
            },
            // barWidth:5,
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      const value = ''
                      return value
                    },
                    textStyle: {
                      color: '#ccc',
                      fontSize: 10
                    }
                  }
                }
              },
              data: [
                {
                  yAxis: this.date_list['amt_' + [this.zhuyao] + '_avg'],
                  symbolSize: 1,
                  itemStyle: {
                    color: '#73D0EF',
                    width: 2
                  },
                  lineStyle: {
                    normal: {
                      width: 1
                    }
                  }
                },
                {
                  yAxis: this.date_list.amt_aim_left_avg,
                  symbolSize: 1,
                  itemStyle: {
                    color: '#FF9C5A',
                    width: 2
                  },
                  lineStyle: {
                    normal: {
                      width: 1
                    }
                  }
                }
              ]
            },
            itemStyle: {
              color: params => {
                if (params.dataIndex >= this.date_list['amt_' + [this.zhuyao] + '_sum_list'].length) {
                  return '#AB95F2'
                } else {
                  return '#73D0EF'
                }
              }
            }
          }],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5
        }
      })
    },
    // 点击选择
    dianji_xuanze (index) {
      if (index == 1) {
        this.jichu(this.wenben)
        this.xianzai_xuanzhong = index
      } else if (index == 2) {
        this.jichu(this.wenben)
        this.xianzai_xuanzhong = index
      } else if (index == 0) {
        this.nianfen_xianshi_yincang = true
      }
    },
    // 看现在
    xian () {
      // this.xian=false;
      // this.wei=true;
      // this.xianshi_1=false;
      // this.yuce=false;
      this.funn_list = []
      this.zhi_zixian = []
      if (this.date_list.amt_funn_sum_list != 0) {
        this.date_list.amt_funn_sum_list[this.date_list.amt_funn_sum_list.length - 1]['amt_' + [this.zhuyao] + '_est_sum'] = -1
      }
      const chang_t = this.date_list['amt_' + [this.zhuyao] + '_sum_list'].length
      this.date_list.amt_aim_sum_list.splice(chang_t, this.date_list.x_axis.length - 1)
      this.date_list.x_axis.splice(chang_t, this.date_list.x_axis.length - 1)
      this.date_list[this.mubiao] = this.date_list.amt_aim_sum_list[this.date_list.amt_aim_sum_list.length - 1]
      this.amt_list.splice(chang_t)
      // this.shuzi_3=(this.date_list.amt_aim_sum_list[this.date_list.amt_aim_sum_list.length-1]).toFixed(2)+'(100.00%)'
      // this.shuzi_1=(this.date_list.amt_con_sum_list[this.date_list.amt_con_sum_list.length-1].amt_con_sum).toFixed(2)+"("+((this.date_list.amt_con_sum_list[this.date_list.amt_con_sum_list.length-1].amt_con_sum/this.date_list.amt_task_aim)*100).toFixed(2)+"%)"
      // this.shuzi_4=(this.date_list.amt_con_sum_list[this.date_list.amt_con_sum_list.length-1].amt_con_sum).toFixed(2)+"("+((this.date_list.amt_con_sum_list[this.date_list.amt_con_sum_list.length-1].amt_con_sum/this.date_list.amt_task_aim)*100).toFixed(2)+"%)"
      // this.shuzi_6=(this.date_list.amt_aim_sum_list[this.date_list.amt_aim_sum_list.length-1]-this.date_list.amt_con_sum_list[this.date_list.amt_con_sum_list.length-1].amt_con_sum).toFixed(2)+'('+(((this.date_list.amt_aim_sum_list[this.date_list.amt_aim_sum_list.length-1]-this.date_list.amt_con_sum_list[this.date_list.amt_con_sum_list.length-1].amt_con_sum)/this.date_list.amt_aim_sum_list[this.date_list.amt_aim_sum_list.length-1])*100).toFixed(2)+"%)"
      // 千位符
      // var reg = /(-?\d+)(\d{3})/;
      // this.shuzi_3=this.shuzi_3.replace(reg, "$1,$2");
      // this.shuzi_1=this.shuzi_1.replace(reg, "$1,$2");
      // this.shuzi_4=this.shuzi_4.replace(reg, "$1,$2");
      // this.shuzi_6=this.shuzi_6.replace(reg, "$1,$2");

      // this.r_xian=1
    },
    // 顾过去选择年份确定
    xuanze_nianfen_queding () {
      if (this.nian_sj != '') {
        const sj = '' + this.nian_sj.getFullYear() + this.pa(this.nian_sj.getMonth() + 1) + '01'
        if (this.wenben == '合同') {
          get_con_daily_history({
            data: {
              date: sj,
              ent_id: this.$ent_id(),
              aim_type: this.dangqian_num + '',
              staff_id: this.dangqian_num == 2 ? this.shaixuan_neirong : null,
              prod_id: this.dangqian_num == 3 ? this.shaixuan_neirong : null,
              dept_id: this.dangqian_num == 1 ? this.shaixuan_neirong : null,
              user_id: this.$jichuxinxi().user_id
            }
          }).then(res => {
            if (res.data.code == 10033) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              this.date_list = date
              this.nianfen_xianshi_yincang = false
              this.xianzai_xuanzhong = 0
              this.sj = this.nian_sj.getFullYear() + '/' + this.pa(this.nian_sj.getMonth() + 1) + '/'
              this.danwei()
            } else if (res.data.code == 10034) {
              // "获取失败"
            }
          })
        } else if (this.wenben == '回款') {
          get_rec_daily_history({
            data: {
              date: sj,
              ent_id: this.$ent_id(),
              aim_type: this.dangqian_num + '',
              staff_id: this.dangqian_num == 2 ? this.shaixuan_neirong : null,
              prod_id: this.dangqian_num == 3 ? this.shaixuan_neirong : null,
              dept_id: this.dangqian_num == 1 ? this.shaixuan_neirong : null,
              user_id: this.$jichuxinxi().user_id
            }
          }).then(res => {
            if (res.data.code == 10052) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              this.date_list = date
              this.nianfen_xianshi_yincang = false
              this.xianzai_xuanzhong = 0
              this.sj = this.nian_sj.getFullYear() + '/' + this.pa(this.nian_sj.getMonth() + 1) + '/'
              this.danwei()
            } else if (res.data.code == 10053) {
              //  "获取失败"
            } else if (res.data.code == 10054) {
              // 没有数据
            }
            console.log(res)
          })
        } else if (this.wenben == '发票') {
          get_inv_daily_history({
            data: {
              date: sj,
              ent_id: this.$ent_id(),
              aim_type: this.dangqian_num + '',
              staff_id: this.dangqian_num == 2 ? this.shaixuan_neirong : null,
              prod_id: this.dangqian_num == 3 ? this.shaixuan_neirong : null,
              dept_id: this.dangqian_num == 1 ? this.shaixuan_neirong : null,
              user_id: this.$jichuxinxi().user_id
            }
          }).then(res => {
            if (res.data.code == 10043) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              this.date_list = date
              this.nianfen_xianshi_yincang = false
              this.xianzai_xuanzhong = 0
              this.sj = this.nian_sj.getFullYear() + '/' + this.pa(this.nian_sj.getMonth() + 1) + '/'
              this.danwei()
            } else if (res.data.code == 10044) {
              // "获取失败";
            } else if (res.data.code == 10045) {
              // 没有数据
            }
            console.log(res)
          })
        }
      }
    },
    xuanze_zhanshi_jiekou (num) {
      if (num == 1) {
        // (this.quanbu_kejian||this.zhu_guanli_yuan||this.zi_guanli_yuan)?null:
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.quanbu_kejian || this.zhuguan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.bumen_tree = date
          } else if (res.data.code == 10169) {}
        })
        this.bumen = true
      } else if (num == 2) {
        // chaosong_qiye_bumen_list
        // (this.quanbu_kejian||this.zhu_guanli_yuan||this.zi_guanli_yuan)?null:
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.quanbu_kejian || this.zhuguan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(item => {
              this.$set(item, 'dept_name', item.label)
            })
            this.chaosong_qiye_bumen_list = date
          } else if (res.data.code == 10169) {}
        })
        this.shaixuan_ren = true
      } else if (num == 3) {
        // (this.quanbu_kejian||this.zhu_guanli_yuan||this.zi_guanli_yuan)?null:
        query_prod_tree_sale_rule({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.quanbu_kejian || this.zhuguan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.chanpin_tree = date
          } else if (res.data.code == 500) {}
        })
        this.xuanze_chanpin = true
      }
    },
    bumen_sure () {
      console.log(this.$func.getSimpleCheckedNodes(this.$refs.tree.store))
      const _this = this
      // if(this.dangqian_num=='1'){
      const datb = this.$func.getSimpleCheckedNodes(this.$refs.tree.store)
      let dept_id = null
      if (datb.length != 0) {
        dept_id = datb.map(item => item.dept_id).join(',')
        this.shaixuan_name = datb.length != 0 ? datb.map(item => item.label).join(',') : ''
      } else {
        this.shaixuan_name = ''
      }
      this.shaixuan_neirong = dept_id
      // this.jichu(this.wenben)
      // this.bumen=false
      // dept_id

      // }
      if (this.xianzai_xuanzhong != 0) {
        this.jichu(this.wenben)
        this.bumen = false

        // dept_id
      } else {
        this.xuanze_nianfen_queding()
        this.bumen = false
      }
    },
    chanpin_sure () {
      // if(this.dangqian_num=='3'){
      let prod_id = null
      if (this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().length != 0) {
        prod_id = this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().map(item => item.id).join(',')
        this.shaixuan_name = this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().map((item, index) => item.label).join(',')
        console.log('产品', this.$refs.xiaoshou_chanpin_tree.getCheckedNodes())

      } else {
        this.shaixuan_name = ''
      }
      this.shaixuan_neirong = prod_id
      // this.jichu(this.wenben)
      // this.xuanze_chanpin=false
      // prod_id

      // }
      if (this.xianzai_xuanzhong != 0) {
        this.jichu(this.wenben)
        this.xuanze_chanpin = false

        // dept_id
      } else {
        this.xuanze_nianfen_queding()
        this.xuanze_chanpin = false
      }
    },
    shaixuan_sousuo () {
      if (this.sousuo_text == null || this.sousuo_text.length == 0) {
        this.sousuo_text = null
        this.renyuan_chaosong_quanxuan = false
        this.chaosong_bumen_renyuan_jiekou()
      } else {
        get_staff_list_like({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            staff_name: this.sousuo_text,
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10027) {
            const date = JSON.parse(res.data.body.data)
            date.forEach(item => {
              this.$set(item, 'zhi', false)
              this.$set(item, 'name', item.staff_name)
            })
            this.chaosong_qiye_renyuan_list = date
            this.panduan_youce_zuoce_have()
          } else if (res.data.code == 10028) {
          }
        })
      }
    },
    mianbao_chaosong_dian (i, index) {
      if (this.mianbao_chaosong_list.length - 1 != index && index != 0) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length)
        this.chaosong_bumen_renyuan_jiekou()
      } else if (index == 0) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length)
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.quanbu_kejian || this.zhuguan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(item => {
              this.$set(item, 'dept_name', item.label)
            })
            this.chaosong_qiye_bumen_list = date
          } else if (res.data.code == 10169) {}
        })
        this.chaosong_qiye_renyuan_list = []
      }
    },
    dianji_chaosong_quanxuan () {
      this.renyuan_chaosong_quanxuan = !this.renyuan_chaosong_quanxuan
      if (this.renyuan_chaosong_quanxuan) {
        // for(let i=0;i<this.chaosong_qiye_bumen_list.length;i++){
        // this.chaosong_qiye_bumen_list.map(item=>{
        //     item.zhi=true
        //     this.$set(item,'name',item.dept_name)
        //     this.$set(item,'ids',item.dept_id)
        //     this.$set(item,'type',1)
        //     this.chaosong_renyuan_list_xuan.push(item)
        // })
        // }
        // for(let i=0;i<this.chaosong_qiye_renyuan_list.length;i++){
        this.chaosong_qiye_renyuan_list.map(item => {
          item.zhi = true
          this.$set(item, 'name', item.staff_name)
          this.$set(item, 'ids', item.staff_id)
          this.$set(item, 'type', 2)
          this.chaosong_renyuan_list_xuan.push(item)
        })
        // }
      } else {
        this.chaosong_renyuan_list_xuan = []
        // this.chaosong_qiye_bumen_list.map(item=>{
        //     item.zhi=false
        // })
        this.chaosong_qiye_renyuan_list.forEach(item => {
          item.zhi = false
        })
      }
    },
    dianji_chaosong_bumen (i) {
      this.mianbao_chaosong_list.push({ name: i.dept_name, dept_id: i.dept_id })
      this.chaosong_bumen_renyuan_jiekou()
    },
    // 抄送人部门人员列表
    chaosong_bumen_renyuan_jiekou () {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1',
          parent_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          // date.map(item=>{this.$set(item,'zhi',false)})
          console.log(date)
          this.chaosong_qiye_bumen_list = date
          query_dept_staff_list({
            data: {
              ent_id: this.$ent_id(),
              dept_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id,
              active: '1'
            }
          }).then(ras => {
            if (ras.data.code == 10149) {
              const listdata = JSON.parse(ras.data.body.data)
              listdata.map(item => { this.$set(item, 'zhi', false); this.$set(item, 'name', item.staff_name) })
              console.log(99999, listdata)
              this.chaosong_qiye_renyuan_list = listdata
              this.panduan_youce_zuoce_have()
              this.panduan_chaosong_quanxuan()
            } else if (ras.data.code == 10150) {
            }
          })
        } else if (res.data.code == 10169) {
        }
      })
    },
    // chaosong_bumen(i){
    //     i.zhi=!i.zhi
    //     if(i.zhi){
    //         this.$set(i,'name',i.dept_name)
    //         this.$set(i,'ids',i.dept_id)
    //         this.$set(i,'type',1)
    //         this.chaosong_renyuan_list_xuan.push(i)
    //     }else{
    //         for(let a=0;a<this.chaosong_renyuan_list_xuan.length;a++){
    //             if(this.chaosong_renyuan_list_xuan[a].ids==i.dept_id){
    //                 this.chaosong_renyuan_list_xuan.splice(a,1)
    //             }
    //         }
    //     }
    //     this.panduan_chaosong_quanxuan()
    // },
    chaosong_renyuan_sure (i) {
      let staff_id = null
      // if(this.dangqian_num=='2'){
      // this.chaosong_renyuan_list_xuan.length!=0?this.chaosong_renyuan_list_xuan.map(ite=>ite.staff_id).join(','):null
      this.shaixuan_name = this.chaosong_renyuan_list_xuan.map(ite => ite.staff_name).join(',')
      console.log('人员', this.chaosong_renyuan_list_xuan)
      staff_id = this.chaosong_renyuan_list_xuan.map(ite => ite.staff_id).join(',')

      // }else{
      //     this.shaixuan_name=''
      // }
      this.shaixuan_neirong = staff_id
      // this.jichu(this.wenben)
      // this.shaixuan_ren=false

      if (this.xianzai_xuanzhong != 0) {
        this.jichu(this.wenben)
        this.shaixuan_ren = false

        // dept_id
      } else {
        this.xuanze_nianfen_queding()
        this.shaixuan_ren = false
      }
    },
    // 判断抄送是否全选
    panduan_chaosong_quanxuan () {
      const list = []
      // for(let i=0;i<this.chaosong_qiye_bumen_list.length;i++){
      //     if(!this.chaosong_qiye_bumen_list[i].zhi){
      //         list.push('111')
      //     }
      // }
      for (let i = 0; i < this.chaosong_qiye_renyuan_list.length; i++) {
        if (!this.chaosong_qiye_renyuan_list[i].zhi) {
          list.push('111')
        }
      }
      if (list.length == 0) {
        this.renyuan_chaosong_quanxuan = true
      } else {
        this.renyuan_chaosong_quanxuan = false
      }
    },
    // 判断右侧有的左侧是否有
    panduan_youce_zuoce_have () {
      for (let i = 0; i < this.chaosong_renyuan_list_xuan.length; i++) {
        // for(let a=0;a<this.chaosong_qiye_bumen_list.length;a++){
        //     if(this.chaosong_renyuan_list_xuan[i].ids==this.chaosong_qiye_bumen_list[a].dept_id){
        //         this.chaosong_qiye_bumen_list[a].zhi=true
        //     }
        // }
        for (let a = 0; a < this.chaosong_qiye_renyuan_list.length; a++) {
          if (this.chaosong_renyuan_list_xuan[i].ids == this.chaosong_qiye_renyuan_list[a].staff_id) {
            this.chaosong_qiye_renyuan_list[a].zhi = true
          }
        }
      }
    },
    dianji_chaosong_xuanren (i) {
      console.log(i)
      i.zhi = !i.zhi
      if (i.zhi) {
        this.$set(i, 'name', i.staff_name)
        this.$set(i, 'ids', i.staff_id)
        this.$set(i, 'type', 2)
        this.chaosong_renyuan_list_xuan.push(i)
      } else {
        for (let a = 0; a < this.chaosong_renyuan_list_xuan.length; a++) {
          if (this.chaosong_renyuan_list_xuan[a].ids == i.staff_id) {
            this.chaosong_renyuan_list_xuan.splice(a, 1)
          }
        }
      }
      this.panduan_chaosong_quanxuan()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../style/salesman.scss';
.dailyb{
    background:#fff;
    width: 96%;
    height:100%;
    position: relative;
    .tit{
        height:0.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.28rem;
        font-size: 0.18rem;
        color:#0F0F0F;
        border-bottom: 0.02rem solid #EEEEEE;
        div{
            height:0.6rem;
            font-size: 0.14rem;
            color:#262626;
            position: relative;
            cursor: pointer;
            p{
                line-height:0.6rem;
                display: flex;
                width: 0.9rem;
                align-items: center;
                justify-content: center;
                i{
                    font-size: 0.12rem;
                    margin-left: 0.1rem;
                }
                .el-icon-caret-bottom{
                    display: none;
                }
            }
            &:hover{
                .el-icon-caret-bottom{
                    display: block;
                }
                .el-icon-caret-left{
                    display: none;
                }
                ul{
                    display: block;
                }
            }
            ul{
                position: absolute;
                display: none;
                top:0.6rem;
                width: 0.9rem;
                height:1.2rem;
                box-shadow:0 0 0.1rem 0 rgba(243,243,246,0.8);
                z-index: 99;
                li{
                    line-height: 0.37rem;
                    text-align: center;
                    width: 0.9rem;
                    &:hover{
                        background: rgb(241, 240, 240);
                    }
                }
            }
        }
        // p{
        //     display: flex;
        //     align-items: center;
        //     font-size: 0.1rem;
        //     color:#4C4A4D;
        // }
    }
    .tit_2{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.38rem;
        padding: 0 0.3rem;
        ul{
            display: flex;
            align-items: center;
            li{
                padding: 0.07rem 0.41rem;
                font-size: 0.12rem;
                border:0.01rem solid #888888;
                color:#888888;
                cursor: pointer;
                &:nth-child(3){
                    border-radius: 0 0.02rem 0.02rem 0;
                }
                &:nth-child(1){
                    border-radius: 0.02rem 0 0 0.02rem;
                }
            }
        }
    }
    .shaixuan{
        // display: flex;
        // align-items: center;
        // font-size: 0.14rem;
        // color:#4C4A4D;
        position: absolute;
        right:0.6rem;
        font-size: 0.14rem;
        display: flex;
        align-items: center;
        p{
            height:0.24rem;
            width:0.68rem;
            line-height: 0.24rem;
            border:0.01rem solid #ccc;
            margin-left: 0.1rem;
            padding: 0 0.16rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
        }
    }
    .shili_tu{
        z-index: 99;
        width: 100%;
        height: 70%;
    }
    .shi_li{
        margin-top: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.96rem;
        li{
            display: flex;
            align-items: center;
            color:#A9A9A9;
            font-size: 0.12rem;
            span{
                display: inline-block;
                height:0.14rem;
                width:0.14rem;
                margin-right: 0.22rem;
                border-radius: 0.02rem;
            }
        }
    }
    .beijing{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.3);
        .box1{
            height:3.03rem;
            width:6.44rem;
            background:#fff;
            border-radius: 0.2rem;
            position: absolute;
            left:50%;
            margin-left: -3.22rem;
            top:50%;
            margin-top: -1.51rem;
            .tit_box{
                font-size: 0.18rem;
                color:#1A2533;
                text-align: center;
                padding-top:0.25rem;
                margin-bottom: 0.45rem;
            }
            .con{
                font-size: 0.14rem;
                color:#4C4A4D;
                text-align: center;
                margin-bottom: 0.2rem;
            }
            .con_box{
                display: flex;
                justify-content: center;
                font-size: 0.12rem;
                color:#4C4A4D;
                align-items: center;
            }
            .foot{
                display: flex;
                justify-content: flex-end;
                margin-right: 0.6rem;
                margin-top:0.64rem;
                span{
                    display: block;
                    height:0.43rem;
                    width:0.83rem;
                    line-height: 0.43rem;
                    border:0.01rem solid #979797;
                    text-align: center;
                    font-size: 0.14rem;
                    color:#888888;
                    border-radius: 0.02rem;
                    cursor: pointer;
                    &:nth-child(1){
                        background: #F16977;
                        border:0.01rem solid #F16977;
                        color:#fff;
                        margin-right:0.19rem;
                    }
                }
            }
        }
    }
}
</style>
