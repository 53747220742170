<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="salesana">
        <ul class="tit">
            <li :class="wenben=='合同'?'bei':''" @click="wenben='合同'">
                合同
            </li>
            <li :class="wenben=='发票'?'bei':''" @click="wenben='发票'">
                发票
            </li>
            <li :class="wenben=='回款'?'bei':''" @click="wenben='回款'">
                回款
            </li>
        </ul>
        <div class="con">
            <div class="con_box">
                <rbaoone ref="rbao1"></rbaoone>
            </div>
            <div class="con_box">
                <rbaotwo ref="rbao2"></rbaotwo>
            </div>
            <div class="con_box">
                <yuebaoone ref="yuebao1"></yuebaoone>
            </div>
            <div class="con_box">
                <yuebaotwo ref="yuebao2"></yuebaotwo>
            </div>
            <div class="con_box">
                <nianbaoone ref="nianbao1"></nianbaoone>
            </div>
            <div class="con_box">
                <nianbaotwo ref="nianbao2"></nianbaotwo>
            </div>
        </div>
    </div>
</template>

<script>
// import { } from '../../api/api.js'
import rbaoone from '../Chart/Daily'
import rbaotwo from '../Chart/Dailyb'
import yuebaoone from '../Chart/Monthlya'
import yuebaotwo from '../Chart/Monthlyb'
import nianbaoone from '../Chart/Annuala'
import nianbaotwo from '../Chart/Annualb'
export default {
  name: 'salesana',
  data () {
    return {
      wenben: '合同'
    }
  },
  components: { rbaoone, rbaotwo, yuebaoone, yuebaotwo, nianbaoone, nianbaotwo },
  mounted () {
    this.jichu()
  },
  created () {
  },
  watch: {
    wenben () {
      this.jichu()
    }
  },
  methods: {
    jichu () {
      // this.$bendi_cunfang_cunchu('pc_mlbb_xiaoshou_biao_biaoti','合同','暂时')
      console.log(this.$refs)
      this.$refs.rbao1.jichu(this.wenben)
      this.$refs.rbao2.jichu(this.wenben)
      this.$refs.yuebao1.jichu(this.wenben)
      this.$refs.yuebao2.jichu(this.wenben)
      this.$refs.nianbao1.jichu(this.wenben)
      this.$refs.nianbao2.jichu(this.wenben)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.salesana {
    // min-height:100%;
    width:100%;
    .tit{
        display: flex;
        align-items: center;
        padding-left: 0.2rem;
        background: #fff;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;
        height: 0.78rem;
        li{
            height:0.78rem;
            line-height: 0.78rem;
            min-width:0.72rem;
            margin-right: 0.8rem;
            color:#1A2533;
            font-size: 0.16rem;
            text-align: center;
            cursor: pointer;
        }
        .bei{
            color: #9a86db;
            border-bottom: 0.02rem solid #9a86db;
        }
    }
    .con{
        margin-top: 0.9rem;
        display: flex;
        flex-wrap: wrap;
        .con_box{
            height:9rem;
            width:50%;
            margin-bottom: 0.2rem;
        }
    }
}
</style>
