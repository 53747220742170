<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="annualb">
        <div class="tit">
            <div>
                <p v-if="!shifou_geren">{{xuanze_leixing(dangqian_num)}}
                    <i class="el-icon-caret-left"></i>
                    <i class="el-icon-caret-bottom"></i>
                </p>
                <ul v-if="!shifou_geren">
                    <li v-for="i in leixing" :key="i.id" :class="dangqian_num==i.id?'lanse':''" @click="dangqian_num=i.id">{{i.label}}</li>
                </ul>
            </div>
            {{wenben}}年报
            <p>
                <!-- 全屏 -->
            </p>
        </div>
        <div class="tit_2">
            <!-- <p></p> -->
            <ul>
                <li @click="dianji_xuanze(index)" v-for='(i,index) in biao' :key='index' :style="xianzai_xuanzhong==index?'background:#F96C6C;border:0.01rem solid red;color:#fff':''">{{i}}</li>
            </ul>
            <div v-if="!shifou_geren" class="shaixuan">筛选{{xuanze_leixing(dangqian_num)}}
                <el-tooltip class="item" effect="dark" :content="shaixuan_name" placement="top">
                    <p @click="xuanze_zhanshi_jiekou(dangqian_num)">{{shaixuan_name}}</p>
                </el-tooltip>
            </div>
        </div>
        <div class="zhuanhuan">
          <img id='hun' :src="hunhe?require('../../assets/hun_no.png'):require('../../assets/hun_ok.png')" alt="">
          <img id="zhu" :src="zhuzhuang?require('../../assets/zhu_no.png'):require('../../assets/zhu_ok.png')" alt="">
          <img id="zhe" :src="zhexian?require('../../assets/zhe_no.png'):require('../../assets/zhe_ok.png')" alt="">
        </div>
        <div v-if="!shiyou_shuju" id="nianbao2" :style="{width: '100%', height: '70%'}"></div>
        <img v-if="shiyou_shuju" class="shili_tu" src="../../assets/images/nianbao_shili2.png" alt="">
        <ul class="shi_li">
            <li v-for="(i,index) in shili" :key='index'><span :style="i.color"></span>{{i.name}}</li>
        </ul>
        <el-dialog title="选择部门" :center="true" :show-close='false' append-to-body :visible.sync="bumen">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="nei_box">
                        <!-- check-strictly -->
                        <el-tree
                            :data="bumen_tree"
                            show-checkbox
                            default-expand-all
                            ref="tree"
                            highlight-current
                            :props="tree_guize"
                            style="background: #E7E8EB"
                            >
                            <!-- @check='fuxuan' -->
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="bumen_sure">确定</p>
                <p class="quxiao" @click="bumen=false">取消</p>
            </div>
        </el-dialog>
        <el-dialog title="选择产品" :center="true" :show-close='false' append-to-body :visible.sync="xuanze_chanpin">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="nei_box">
                        <!-- check-strictly -->
                        <el-tree
                            :data="chanpin_tree"
                            show-checkbox
                            default-expand-all
                            ref="xiaoshou_chanpin_tree"
                            highlight-current
                            :props="tree_guize"
                            check-strictly
                            style="background: #E7E8EB"
                            >
                            <!-- @check='fuxuan' -->
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="chanpin_sure">确定</p>
                <p class="quxiao" @click="xuanze_chanpin=false">取消</p>
            </div>
        </el-dialog>
        <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="shaixuan_ren">
            <div class="tiao_bumen2">
                <div class="xuanze_left">
                    <p class="tit">选择：</p>
                    <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" v-model="sousuo_text" @keyup='shaixuan_sousuo'>
                        <!--  @keyup="keyu" -->
                    </div>
                    <div class="mianbaoxie">
                        <div v-for="(a,idx) in mianbao_chaosong_list" :key='idx'  @click="mianbao_chaosong_dian(a,idx)">
                            <p :class="mianbao_chaosong_list.length-1==idx?'p1':'p2'">{{a.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="quanxuan" v-if="chaosong_qiye_renyuan_list.length!=0">
                        <img @click="dianji_chaosong_quanxuan" :src="renyuan_chaosong_quanxuan?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                        <span>全选</span>
                    </div>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_bumen_list" :key="idx" @click="dianji_chaosong_bumen(a)">
                                    <!-- <img class="img2" @click.stop='chaosong_bumen(a)' :src="a.zhi?require('../assets/okblue.png'):require('../assets/noccc.png')" alt=""> -->
                                    <p style="margin-left:0.16rem;">{{a.dept_name}}</p>
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_renyuan_list" :key="idx" style='justify-content:left;margin-left:-0.07rem;'>
                                    <img class="img2" @click="dianji_chaosong_xuanren(a)" :src="a.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    <p>{{a.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="xuanze_right">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_renyuan_list_xuan" :key="idx">
                                    <p>{{a.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="chaosong_renyuan_sure">确定</p>
                <p class="quxiao" @click="shaixuan_ren=false;chaosong_renyuan_list_xuan=[]">取消</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { query_all_module_read_power_list, query_user_info, query_prod_tree_sale_rule, get_staff_list_like, query_dept_staff_list, query_ent_dept_list, query_ent_dept_list_aim, get_inv_year, get_rec_year, get_con_year } from '../../api/api'
export default {
  name: 'annualb',
  data () {
    return {
      wenben: '',
      biao: ['看现在', '望未来'],
      shili: [
        {
          name: '目标',
          color: 'background:#FF9C59'
        },
        {
          name: '已完成',
          color: 'background:#73D0EF'
        },
        {
          name: '预测',
          color: 'background:#AB95F2'
        },
        {
          name: '达标',
          color: 'background:#6ABFA7'
        },
        {
          name: '未达标',
          color: 'background:#F16977'
        }
      ],
      xianzai_xuanzhong: '0',
      date_list: [], //
      yuan: '元',
      shuzhi: '1',
      mubiao: '',
      sj: '', // 当前时间
      zhuyao: '',
      nian_sj: '',
      nianfen_xianshi_yincang: false,
      blue: [],
      green: [],
      orange: [],
      red: [],
      yellow: [],
      violet: [],
      aim: [],
      xian_shijian: [],
      lanxian: [],
      zixian: [],
      huangdian: [],
      hunhe: false,
      zhuzhuang: true,
      zhexian: true,
      dangqian_num: '',
      leixing: [],
      shaixuan_name: '',
      shaixuan_neirong: null, // 筛选内容
      bumen: false,
      bumen_tree: [],
      tree_guize: {
        children: 'children',
        label: 'label'
      },
      zhu_guanli_yuan: false,
      zhuguan: false,
      shifou_keshi: false,
      xuanze_chanpin: false,
      chanpin_tree: [],
      path: '',
      sousuo_text: '',
      mianbao_chaosong_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      renyuan_chaosong_quanxuan: false,
      chaosong_renyuan_list_xuan: [],
      chaosong_qiye_renyuan_list: [],
      chaosong_qiye_bumen_list: [],
      shaixuan_ren: false,
      quanbu_kejian: false,
      wenzi_zhanshi_yincang: false,
      wenzi_zhanshi_yincang3: false,
      shiyou_shuju: false,

      shifou_geren: true// 是否是只选择产品  条件  部门主管   子管理员  主管   都不是
    }
  },
  created () {
    // this.wenben=this.$bendi_naqu_cunchu('pc_mlbb_xiaoshou_biao_biaoti','暂时')
    // this.jichu()
    this.quanxian()
    this.mianbao_chaosong_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  mounted () {

  },
  watch: {
    dangqian_num () {
      this.shaixuan_neirong = null
      this.shaixuan_name = ''
      this.jichu(this.wenben)
    },
    shaixuan_ren () {
      if (!this.shaixuan_ren) {
        this.chaosong_renyuan_list_xuan = []
        this.chaosong_qiye_renyuan_list = []
        this.renyuan_chaosong_quanxuan = false
        this.mianbao_chaosong_list = [
          {
            name: JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name,
            dept_id: '0'
          }
        ]
      }
    },
    wenben () {
      this.xianzai_xuanzhong = '0'
    }
  },
  methods: {
    pa (s) {
      return s < 10 ? '0' + s : s
    },
    // 获取本月时间
    jinyue () {
      this.sj = new Date()
      var xian_sj = this.sj
      this.sj =
                xian_sj.getFullYear() + '/' + this.pa(xian_sj.getMonth() + 1) + '/'
      console.log(6666, this.sj)
    },
    jichu (wenben) {
      this.wenben = wenben
      this.jinyue()
      if (this.wenben == '合同') {
        get_con_year({
          data: {
            ent_id: this.$ent_id(),
            aim_type: this.shifou_geren ? '2' : this.dangqian_num + '',
            staff_id: this.dangqian_num == 2 ? this.shaixuan_neirong : null,
            prod_id: this.dangqian_num == 3 ? this.shaixuan_neirong : null,
            dept_id: this.dangqian_num == 1 ? this.shaixuan_neirong : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          if (res.data.code == 10039) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.date_list = date
            this.zhuyao = 'con'
            this.color = '#73D0EF'
            this.shiyou_shuju = false
            this.danwei()
          } else if (res.data.code == 10040) {
            // "合成失败"
            this.shiyou_shuju = true
          } else if (res.data.code == 10041) {
            // 空
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          } else if (res.data.code == 12200) {
            // 没有目标数据
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          }
        })
      } else if (this.wenben == '回款') {
        get_rec_year({
          data: {
            ent_id: this.$ent_id(),
            est_type: '1',
            aim_type: this.shifou_geren ? '2' : this.dangqian_num + '',
            staff_id: this.dangqian_num == 2 ? this.shaixuan_neirong : null,
            prod_id: this.dangqian_num == 3 ? this.shaixuan_neirong : null,
            dept_id: this.dangqian_num == 1 ? this.shaixuan_neirong : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          if (res.data.code == 10058) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.date_list = date
            this.zhuyao = 'rec'
            this.color = '#CAE024'
            this.shiyou_shuju = false
            this.danwei()
          } else if (res.data.code == 10059) {
            // "合成失败"
            this.shiyou_shuju = true
          } else if (res.data.code == 10060) {
            // kong
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          } else if (res.data.code == 12200) {
            // 没有目标数据
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          }
        })
      } else if (this.wenben == '发票') {
        get_inv_year({
          data: {
            ent_id: this.$ent_id(),
            est_type: '1',
            aim_type: this.shifou_geren ? '2' : this.dangqian_num + '',
            staff_id: this.dangqian_num == 2 ? this.shaixuan_neirong : null,
            prod_id: this.dangqian_num == 3 ? this.shaixuan_neirong : null,
            dept_id: this.dangqian_num == 1 ? this.shaixuan_neirong : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10049) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.date_list = date
            this.zhuyao = 'inv'
            this.color = '#5596D4'
            this.shiyou_shuju = false
            this.danwei()
          } else if (res.data.code == 10050) {
            // "合成失败"
            this.shiyou_shuju = true
          } else if (res.data.code == 10051) {
            // kong
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          } else if (res.data.code == 12200) {
            // 没有目标数据
            this.shiyou_shuju = false
            this.$message({
              message: '暂无数据',
              type: 'warning'
            })
          }
        })
      }
    },
    xuanze_leixing (num) {
      if (num == 1) {
        return '部门'
      } else if (num == 2) {
        return '人员'
      } else if (num == 3) {
        return '产品'
      }
    },
    quanxian () {
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
            this.zhuguan = true
          } else {
            this.zhuguan = false
          }
          if (res.data.body.dept_manager == 1 || res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
            this.shifou_geren = false // 是否是直选产品
            this.leixing = [
              {
                label: '部门',
                id: '1'
              },
              {
                label: '人员',
                id: '2'
              },
              {
                label: '产品',
                id: '3'
              }
            ]
            this.dangqian_num = '1'
          } else {
            this.shifou_geren = true // 是否是直选产品
            this.leixing = [
              {
                label: '产品',
                id: '3'
              }
            ]
            this.dangqian_num = '3'
          }
        }
      })
      query_all_module_read_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            this.quanbu_kejian = true
          } else {
            this.quanbu_kejian = false
          }
        } else if (res.data.code == 500) {}
      })
    },
    // 单位
    danwei () {
      const zongmubiao = this.date_list.amt_aim.toFixed(0) + ''
      console.log(zongmubiao)
      if (zongmubiao.length <= 4) {
        this.yuan = '元'
        this.shuzhi = 1
      } else if (zongmubiao.length <= 8 && zongmubiao.length > 4) {
        this.yuan = '万元'
        this.shuzhi = 10000
      } else if (zongmubiao.length > 8) {
        this.yuan = '亿'
        this.shuzhi = 100000000
      }
      // 数据转换
      this.month_list = this.date_list[[this.zhuyao] + '_year_list']
      this.date_list.amt_aim = Number((this.date_list.amt_aim / this.shuzhi).toFixed(2))
      this.date_list.amt_avg_aim = Number((this.date_list.amt_avg_aim / this.shuzhi).toFixed(2))
      this.date_list.amt_avg_have = Number((this.date_list.amt_avg_have / this.shuzhi).toFixed(2))
      this.date_list['amt_' + [this.zhuyao] + '_sum'] = Number((this.date_list['amt_' + [this.zhuyao] + '_sum'] / this.shuzhi).toFixed(2))
      this.date_list['amt_' + [this.zhuyao] + '_sum_est'] = Number((this.date_list['amt_' + [this.zhuyao] + '_sum_est'] / this.shuzhi).toFixed(2))
      this.date_list['amt_' + [this.zhuyao] + '_sum_should'] = Number((this.date_list['amt_' + [this.zhuyao] + '_sum_should'] / this.shuzhi).toFixed(2))
      this.date_list.amt_gap_avg = Number((this.date_list.amt_gap_avg / this.shuzhi).toFixed(2))
      this.date_list.in_blue = Number((this.date_list.in_blue / this.shuzhi).toFixed(2))
      this.date_list.in_red = Number((this.date_list.in_red / this.shuzhi).toFixed(2))
      this.date_list.in_violet = Number((this.date_list.in_violet / this.shuzhi).toFixed(2))
      this.date_list.out_blue = Number((this.date_list.out_blue / this.shuzhi).toFixed(2))
      this.date_list.out_green = Number((this.date_list.out_green / this.shuzhi).toFixed(2))
      this.date_list.out_orange = Number((this.date_list.out_orange / this.shuzhi).toFixed(2))
      this.date_list.out_red = Number((this.date_list.out_red / this.shuzhi).toFixed(2))
      this.date_list.out_yellow = Number((this.date_list.out_yellow / this.shuzhi).toFixed(2))
      this.blue = []
      this.green = []
      this.orange = []
      this.red = []
      this.yellow = []
      this.violet = []
      this.aim = []
      this.xian_shijian = []
      this.lanxian = []
      this.zixian = []
      this.huangdian = []
      for (let i = 0; i < this.month_list.length; i++) {
        this.month_list[i].amt_aim = Number((this.month_list[i].amt_aim / this.shuzhi).toFixed(2))
        this.month_list[i].out_blue = Number((this.month_list[i].out_blue / this.shuzhi).toFixed(2))
        this.month_list[i].out_green = Number((this.month_list[i].out_green / this.shuzhi).toFixed(2))
        this.month_list[i].out_orange = Number((this.month_list[i].out_orange / this.shuzhi).toFixed(2))
        this.month_list[i].out_red = Number((this.month_list[i].out_red / this.shuzhi).toFixed(2))
        this.month_list[i].out_violet = Number((this.month_list[i].out_violet / this.shuzhi).toFixed(2))
        this.month_list[i].out_yellow = Number((this.month_list[i].out_yellow / this.shuzhi).toFixed(2))
        // if(this.month_list[i]['amt_'+[this.zhuyao]+'_sum']!=undefined){
        //     this.month_list[i]['amt_'+[this.zhuyao]+'_sum']=Number((this.month_list[i]['amt_'+[this.zhuyao]+'_sum']/this.shuzhi).toFixed(2));
        // }
        // if(this.month_list[i]['amt_'+[this.zhuyao]+'_sum_est']!=undefined){
        //     this.month_list[i]['amt_'+[this.zhuyao]+'_sum_est']=Number((this.month_list[i]['amt_'+[this.zhuyao]+'_sum_est']/this.shuzhi).toFixed(2));
        // }
        // if(this.month_list[i].amt_aim_tow!=undefined){
        //     this.month_list[i].amt_aim_tow=Number((this.month_list[i].amt_aim_tow/this.shuzhi).toFixed(2));
        // }
        // if(this.month_list[i]['amt_'+[this.zhuyao]+'_sum_est']!=undefined){
        //     this.month_list[i]['amt_'+[this.zhuyao]+'_sum_est']=Number((this.month_list[i]['amt_'+[this.zhuyao]+'_sum_est']/this.shuzhi).toFixed(2));
        // }
        this.blue.push(this.month_list[i].out_blue)
        this.green.push(this.month_list[i].out_green)
        this.orange.push(this.month_list[i].out_orange)
        this.red.push(this.month_list[i].out_red)
        this.yellow.push(this.month_list[i].out_yellow)
        this.violet.push(this.month_list[i].out_violet)
        this.aim.push(this.month_list[i].amt_aim)
        this.xian_shijian.push(this.month_list[i].the_month)
        if (this.month_list[i]['amt_' + [this.zhuyao] + '_sum'] != undefined && this.month_list[i]['amt_' + [this.zhuyao] + '_sum_est'] != undefined) {
          this.zhi = Number((this.month_list[i]['amt_' + [this.zhuyao] + '_sum'] + this.month_list[i]['amt_' + [this.zhuyao] + '_sum_est']) / this.shuzhi).toFixed(2)
        }
        if (this.month_list[i].amt_aim_tow < 0) {
          this.month_list[i].amt_aim_tow = 0
        }
        for (let i = 0; i < this.date_list.current_month_num - 1; i++) {
          this.lanxian.push((Number(this.month_list[i]['amt_' + [this.zhuyao] + '_sum']) / this.shuzhi).toFixed(2))
          this.zixian.push('')
          this.huangdian.push('')
        }
        this.lanxian.push(this.zhi)
        this.zixian.push(this.zhi)
        for (let i = this.date_list.current_month_num; i < this.month_list.length; i++) {
          this.zixian.push(Number(this.month_list[i]['amt_' + [this.zhuyao] + '_sum_est'] / this.shuzhi).toFixed(2))
        }
        for (let i = this.date_list.current_month_num - 1; i < this.month_list.length; i++) {
          this.huangdian.push(Number((this.month_list[i].amt_aim_tow / this.shuzhi).toFixed(2)))
        }
      }
      console.log(this.month_list)
      console.log(this.huangdian)

      if (this.xianzai_xuanzhong == 0) {
        this.xian()
      }
      this.canver()
    },
    // 图
    canver () {
      var echarts = require('echarts')
      var myChart = echarts.init(document.getElementById('nianbao2'))
      var oper = ({
        tooltip: {
          trigger: 'axis',
          formatter: parmas => {
            const lists = []
            for (var i = 0; i < parmas.length; i++) {
              console.log(parmas[i])
              if (parmas[i].value != 0 && parmas[i].value != null) {
                lists.time = this.xian_shijian[parmas[i].dataIndex].slice(0, 4) + '/' + this.xian_shijian[parmas[i].dataIndex].slice(4)
                if (parmas[i].seriesType == 'line' && parmas[i].seriesName == '目标') {
                  console.log(parmas[i].marker)
                  lists.push(
                    Object.assign(
                      {},
                      {
                        value: parmas[i].value,
                        name: parmas[i].seriesName,
                        color: '<span style="display:inline-block;margin-right:0.05rem;width:0.1rem;height:0.02rem;background:#FE9D5C"></span>',
                        time: parmas[i].time
                      }
                    )
                  )
                }
                if (parmas[i].seriesType == 'line' && parmas[i].seriesName == '引导目标') {
                  console.log(parmas[i].marker)
                  lists.push(
                    Object.assign(
                      {},
                      {
                        value: parmas[i].value,
                        name: parmas[i].seriesName,
                        color: '<span style="display:inline-block;margin-right:0.05rem;width:0.1rem;height:0.02rem;border-top:0.02rem dotted #FF9C59;"></span>',
                        time: parmas[i].time
                      }
                    )
                  )
                }
                if (parmas[i].seriesType == 'line' && parmas[i].seriesName == '当前完成') {
                  console.log(parmas[i].marker)
                  lists.push(
                    Object.assign(
                      {},
                      {
                        value: parmas[i].value,
                        name: parmas[i].seriesName,
                        color: '<span style="display:inline-block;margin-right:0.05rem;width:0.1rem;height:0.02rem;background:#8BDEFF"></span>',
                        time: parmas[i].time
                      }
                    )
                  )
                }
                if (parmas[i].seriesType == 'line' && parmas[i].seriesName == '预计完成') {
                  console.log(parmas[i].marker)
                  lists.push(
                    Object.assign(
                      {},
                      {
                        value: parmas[i].value,
                        name: parmas[i].seriesName,
                        color: '<span style="display:inline-block;margin-right:0.05rem;width:0.1rem;height:0.02rem;background:#AB95F2"></span>',
                        time: parmas[i].time
                      }
                    )
                  )
                }
                if (parmas[i].seriesType == 'bar') {
                  lists.push(
                    Object.assign(
                      {},
                      {
                        value: parmas[i].value,
                        name: parmas[i].seriesName,
                        color: parmas[i].marker,
                        time: parmas[i].time
                      }
                    )
                  )
                }
                console.log(parmas[i].value)
              }
            }
            let lis = []
            for (let i = 0; i < lists.length; i++) {
              if (lists[i].value != 0) {
                lis.push(lists[i].color + lists[i].name + ':' + lists[i].value + this.yuan + '<br />')
              }
            }
            lis = lis.join('')
            return lists.time + '<br />' + lis
          },
          axisPointer: {
            type: 'line'
          }
        },
        legend: {
          show: false,
          data: ['已完成', '未完成', '预测', '溢出达标', '超额', '当前完成', '预计完成', '目标', '引导目标']
        },
        grid: {
          left: '3%',
          right: '10%',
          bottom: '3%',
          top: '15%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            // boundaryGap : false,
            axisTick: {
              alignWithLabel: true
            },
            name: '(月)',
            nameGap: 6,
            nameTextStyle: {
              color: '#4B4B4B',
              fontSize: 10
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#eee'
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 10,
                color: '#4B4B4B'
              }
            },
            data: this.date_list.x_axis
          }
        ],
        yAxis: [
          {
            name: '单位:' + this.yuan,
            nameTextStyle: {
              color: '#4B4B4B',
              fontSize: 10
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#eee'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#eee'
              }
            },
            axisLabel: {
              textStyle: {
                fontSize: 10,
                color: '#4B4B4B'
              }
            }
          }
        ],
        series: [
          // 折线
          {
            name: '目标',
            type: 'line',
            symbolSize: 3,
            itemStyle: {
              color: '#FF9C59'
            },
            lineStyle: {
              normal: {
                width: 1
              }
            },
            barCategoryGap: 13,
            data: this.aim
          },
          {
            name: '引导目标',
            type: 'line',
            symbolSize: 3,
            itemStyle: {
              normal: {
                width: 1,
                color: '#FE9D5C',
                lineStyle: {
                  color: '#FE9D5C',
                  type: 'dashed' // 'dashed'虚线 'solid'实线
                }
              }
            },
            barCategoryGap: 13,
            data: this.huangdian,
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  label: {
                    formatter: params => {
                      const value = ''
                      return value
                    },
                    textStyle: {
                      color: '#ccc',
                      fontSize: 10
                    }
                  }
                }
              },
              data: [
                {
                  yAxis: this.date_list.amt_avg_have,
                  symbolSize: 1,
                  itemStyle: {
                    color: '#73D0EF',
                    width: 2
                  },
                  lineStyle: {
                    normal: {
                      width: 1
                    }
                  }
                }
              ]
            }
          },
          {
            name: '当前完成',
            type: 'line',
            symbolSize: 3,
            itemStyle: {
              color: '#73D0EF'
            },
            lineStyle: {
              normal: {
                width: 1
              }
            },
            barCategoryGap: 13,
            data: this.lanxian
          },
          {
            name: '预计完成',
            type: 'line',
            symbolSize: 3,
            itemStyle: {
              color: '#AB95F2'
            },
            lineStyle: {
              normal: {
                width: 1
              }
            },
            barCategoryGap: 13,
            data: this.zixian
          },
          // 柱状图
          {
            name: '已完成',
            type: 'bar',
            stack: '总量',
            itemStyle: {
              color: '#8BDEFF'
            },
            barCategoryGap: 13,
            data: this.blue
          },
          {
            name: '预测',
            type: 'bar',
            stack: '总量',
            itemStyle: {
              color: '#AB95F2'
            },
            barCategoryGap: 13,
            data: this.violet
          },
          {
            name: '未完成',
            type: 'bar',
            stack: '总量',
            itemStyle: {
              color: '#F16977'
            },
            barCategoryGap: 13,
            data: this.red
          },
          {
            name: '溢出达标',
            type: 'bar',
            stack: '总量',
            itemStyle: {
              color: '#6ABFA7'
            },
            barCategoryGap: 13,
            data: this.green
          },
          {
            name: '超额',
            type: 'bar',
            stack: '总量',
            itemStyle: {
              color: '#FFDE67'
            },
            barCategoryGap: 13,
            data: this.yellow
          }

        ]
      })
      myChart.setOption(oper)
      console.log(111, oper)
      var qu = myChart.getOption().legend[0].data
      var obj = {}
      const that = this
      $('#zhu').click(function () {
        that.zhuzhuang = false
        that.hunhe = true
        that.zhexian = true
        for (var i = 0; i < oper.series.length; i++) {
          if (oper.series[i].type == 'line') {
            for (let x = 0; x < qu.length; x++) {
              if (oper.series[i].name == qu[x]) {
                obj[qu[x]] = false
              }
            }
          }
          if (oper.series[i].type == 'bar') {
            for (let x = 0; x < qu.length; x++) {
              if (oper.series[i].name == qu[x]) {
                obj[qu[x]] = true
              }
            }
          }
        }
        oper.legend.selected = obj
        myChart.setOption(oper)
      })
      $('#zhe').click(function () {
        that.zhuzhuang = true
        that.hunhe = true
        that.zhexian = false
        for (var i = 0; i < oper.series.length; i++) {
          if (oper.series[i].type == 'line') {
            for (let x = 0; x < qu.length; x++) {
              if (oper.series[i].name == qu[x]) {
                obj[qu[x]] = true
              }
            }
          }
          if (oper.series[i].type == 'bar') {
            for (let x = 0; x < qu.length; x++) {
              if (oper.series[i].name == qu[x]) {
                obj[qu[x]] = false
              }
            }
          }
        }
        oper.legend.selected = obj
        myChart.setOption(oper)
      })
      $('#hun').click(function () {
        that.zhuzhuang = true
        that.hunhe = false
        that.zhexian = true
        for (var i = 0; i < oper.series.length; i++) {
          if (oper.series[i].type == 'line') {
            for (let x = 0; x < qu.length; x++) {
              if (oper.series[i].name == qu[x]) {
                obj[qu[x]] = true
              }
            }
          }
          if (oper.series[i].type == 'bar') {
            for (let x = 0; x < qu.length; x++) {
              if (oper.series[i].name == qu[x]) {
                obj[qu[x]] = true
              }
            }
          }
        }
        oper.legend.selected = obj
        myChart.setOption(oper)
      })
    },
    // 点击选择
    dianji_xuanze (index) {
      this.jichu(this.wenben)
      this.xianzai_xuanzhong = index
    },
    // 看现在
    xian () {
      if (this.date_list.in_blue < this.date_list.amt_aim) {
        this.date_list.in_red = (this.date_list.in_red + this.date_list.in_violet).toFixed(2)
        this.xianshi_3 = true
        const reda = Number(this.date_list[[this.zhuyao] + '_year_list'][this.date_list.current_month_num - 1].out_red + this.date_list[[this.zhuyao] + '_year_list'][this.date_list.current_month_num - 1].out_violet)
        const zong_zhi = this.date_list.current_month_num
        this.red.splice(zong_zhi)
        this.red[zong_zhi - 1] = reda
        this.lanxian[zong_zhi - 1] = this.date_list[[this.zhuyao] + '_year_list'][this.date_list.current_month_num - 1].out_blue
      } else {
        this.date_list.in_red = 0
        this.date_list.out_yellow = 0
        this.date_list.rubbish_value = 0
        this.lanxian[zong_zhi - 1] = this.date_list[[this.zhuyao] + '_year_list'][this.date_list.current_month_num - 1].out_blue + this.date_list[[this.zhuyao] + '_year_list'][this.date_list.current_month_num - 1].out_green
        // if(this.date_list.out_green!=0&&!isNaN(this.date_list.amt_avg_aim)){
        //     this.date_list.x2_axis.splice(12+Number((this.date_list.out_green/this.date_list.amt_avg_aim).toFixed(0)))
        // }else{
        //     this.date_list.x2_axis.splice(12)
        // }
      }
      this.yellow = []
      this.date_list.in_violet = 0
      const zong_zhi = this.date_list.current_month_num
      this.date_list.x_axis.splice(zong_zhi)
      this.blue.splice(zong_zhi)
      this.red.splice(zong_zhi)

      this.orange = []
      this.green.splice(zong_zhi)
      this.yellow.splice(zong_zhi)
      this.zixian = []
      this.huangdian.splice(zong_zhi)
      this.violet = []
    },
    xuanze_zhanshi_jiekou (num) {
      if (num == 1) {
        // (this.quanbu_kejian||this.zhu_guanli_yuan||this.zi_guanli_yuan)?null:
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.quanbu_kejian || this.zhuguan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.bumen_tree = date
          } else if (res.data.code == 10169) {}
        })
        this.bumen = true
      } else if (num == 2) {
        // chaosong_qiye_bumen_list
        // (this.quanbu_kejian||this.zhu_guanli_yuan||this.zi_guanli_yuan)?null:
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.quanbu_kejian || this.zhuguan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(item => {
              this.$set(item, 'dept_name', item.label)
            })
            this.chaosong_qiye_bumen_list = date
          } else if (res.data.code == 10169) {}
        })
        this.shaixuan_ren = true
      } else if (num == 3) {
        // (this.quanbu_kejian||this.zhu_guanli_yuan||this.zi_guanli_yuan)?null:
        query_prod_tree_sale_rule({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.quanbu_kejian || this.zhuguan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.chanpin_tree = date
          } else if (res.data.code == 500) {}
        })
        this.xuanze_chanpin = true
      }
    },
    bumen_sure () {
      console.log(this.$func.getSimpleCheckedNodes(this.$refs.tree.store))
      const _this = this
      if (this.dangqian_num == '1') {
        const datb = this.$func.getSimpleCheckedNodes(this.$refs.tree.store)
        let dept_id = null
        if (datb.length != 0) {
          dept_id = datb.map(item => item.dept_id).join(',')
          this.shaixuan_name = datb.length != 0 ? datb.map(item => item.label).join(',') : ''
        } else {
          this.shaixuan_name = ''
        }
        this.shaixuan_neirong = dept_id
        this.jichu(this.wenben)
        this.bumen = false
        // dept_id
      }
    },
    chanpin_sure () {
      if (this.dangqian_num == '3') {
        let prod_id = null
        if (this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().length != 0) {
          prod_id = this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().map(item => item.id).join(',')
          this.shaixuan_name = this.$refs.xiaoshou_chanpin_tree.getCheckedNodes().map((item, index) => item.label).join(',')
          console.log('产品', this.$refs.xiaoshou_chanpin_tree.getCheckedNodes())

        } else {
          this.shaixuan_name = ''
        }
        this.shaixuan_neirong = prod_id
        this.jichu(this.wenben)
        this.xuanze_chanpin = false
        // prod_id
      }
    },
    shaixuan_sousuo () {
      if (this.sousuo_text == null || this.sousuo_text.length == 0) {
        this.sousuo_text = null
        this.renyuan_chaosong_quanxuan = false
        this.chaosong_bumen_renyuan_jiekou()
      } else {
        get_staff_list_like({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            staff_name: this.sousuo_text,
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10027) {
            const date = JSON.parse(res.data.body.data)
            date.forEach(item => {
              this.$set(item, 'zhi', false)
              this.$set(item, 'name', item.staff_name)
            })
            this.chaosong_qiye_renyuan_list = date
            this.panduan_youce_zuoce_have()
          } else if (res.data.code == 10028) {
          }
        })
      }
    },
    mianbao_chaosong_dian (i, index) {
      if (this.mianbao_chaosong_list.length - 1 != index && index != 0) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length)
        this.chaosong_bumen_renyuan_jiekou()
      } else if (index == 0) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length)
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.quanbu_kejian || this.zhuguan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(item => {
              this.$set(item, 'dept_name', item.label)
            })
            this.chaosong_qiye_bumen_list = date
          } else if (res.data.code == 10169) {}
        })
        this.chaosong_qiye_renyuan_list = []
      }
    },
    dianji_chaosong_quanxuan () {
      this.renyuan_chaosong_quanxuan = !this.renyuan_chaosong_quanxuan
      if (this.renyuan_chaosong_quanxuan) {
        // for(let i=0;i<this.chaosong_qiye_bumen_list.length;i++){
        // this.chaosong_qiye_bumen_list.map(item=>{
        //     item.zhi=true
        //     this.$set(item,'name',item.dept_name)
        //     this.$set(item,'ids',item.dept_id)
        //     this.$set(item,'type',1)
        //     this.chaosong_renyuan_list_xuan.push(item)
        // })
        // }
        // for(let i=0;i<this.chaosong_qiye_renyuan_list.length;i++){
        this.chaosong_qiye_renyuan_list.map(item => {
          item.zhi = true
          this.$set(item, 'name', item.staff_name)
          this.$set(item, 'ids', item.staff_id)
          this.$set(item, 'type', 2)
          this.chaosong_renyuan_list_xuan.push(item)
        })
        // }
      } else {
        this.chaosong_renyuan_list_xuan = []
        // this.chaosong_qiye_bumen_list.map(item=>{
        //     item.zhi=false
        // })
        this.chaosong_qiye_renyuan_list.forEach(item => {
          item.zhi = false
        })
      }
    },
    dianji_chaosong_bumen (i) {
      this.mianbao_chaosong_list.push({ name: i.dept_name, dept_id: i.dept_id })
      this.chaosong_bumen_renyuan_jiekou()
    },
    // 抄送人部门人员列表
    chaosong_bumen_renyuan_jiekou () {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1',
          parent_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          // date.map(item=>{this.$set(item,'zhi',false)})
          console.log(date)
          this.chaosong_qiye_bumen_list = date
          query_dept_staff_list({
            data: {
              ent_id: this.$ent_id(),
              dept_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id,
              active: '1'
            }
          }).then(ras => {
            if (ras.data.code == 10149) {
              const listdata = JSON.parse(ras.data.body.data)
              listdata.map(item => { this.$set(item, 'zhi', false); this.$set(item, 'name', item.staff_name) })
              console.log(99999, listdata)
              this.chaosong_qiye_renyuan_list = listdata
              this.panduan_youce_zuoce_have()
              this.panduan_chaosong_quanxuan()
            } else if (ras.data.code == 10150) {
            }
          })
        } else if (res.data.code == 10169) {
        }
      })
    },
    // chaosong_bumen(i){
    //     i.zhi=!i.zhi
    //     if(i.zhi){
    //         this.$set(i,'name',i.dept_name)
    //         this.$set(i,'ids',i.dept_id)
    //         this.$set(i,'type',1)
    //         this.chaosong_renyuan_list_xuan.push(i)
    //     }else{
    //         for(let a=0;a<this.chaosong_renyuan_list_xuan.length;a++){
    //             if(this.chaosong_renyuan_list_xuan[a].ids==i.dept_id){
    //                 this.chaosong_renyuan_list_xuan.splice(a,1)
    //             }
    //         }
    //     }
    //     this.panduan_chaosong_quanxuan()
    // },
    chaosong_renyuan_sure (i) {
      let staff_id = null
      if (this.dangqian_num == '2') {
        // this.chaosong_renyuan_list_xuan.length!=0?this.chaosong_renyuan_list_xuan.map(ite=>ite.staff_id).join(','):null
        this.shaixuan_name = this.chaosong_renyuan_list_xuan.map(ite => ite.staff_name).join(',')
        console.log('人员', this.chaosong_renyuan_list_xuan)
        staff_id = this.chaosong_renyuan_list_xuan.map(ite => ite.staff_id).join(',')

      } else {
        this.shaixuan_name = ''
      }
      this.shaixuan_neirong = staff_id
      this.jichu(this.wenben)
      this.shaixuan_ren = false
    },
    // 判断抄送是否全选
    panduan_chaosong_quanxuan () {
      const list = []
      // for(let i=0;i<this.chaosong_qiye_bumen_list.length;i++){
      //     if(!this.chaosong_qiye_bumen_list[i].zhi){
      //         list.push('111')
      //     }
      // }
      for (let i = 0; i < this.chaosong_qiye_renyuan_list.length; i++) {
        if (!this.chaosong_qiye_renyuan_list[i].zhi) {
          list.push('111')
        }
      }
      if (list.length == 0) {
        this.renyuan_chaosong_quanxuan = true
      } else {
        this.renyuan_chaosong_quanxuan = false
      }
    },
    // 判断右侧有的左侧是否有
    panduan_youce_zuoce_have () {
      for (let i = 0; i < this.chaosong_renyuan_list_xuan.length; i++) {
        // for(let a=0;a<this.chaosong_qiye_bumen_list.length;a++){
        //     if(this.chaosong_renyuan_list_xuan[i].ids==this.chaosong_qiye_bumen_list[a].dept_id){
        //         this.chaosong_qiye_bumen_list[a].zhi=true
        //     }
        // }
        for (let a = 0; a < this.chaosong_qiye_renyuan_list.length; a++) {
          if (this.chaosong_renyuan_list_xuan[i].ids == this.chaosong_qiye_renyuan_list[a].staff_id) {
            this.chaosong_qiye_renyuan_list[a].zhi = true
          }
        }
      }
    },
    dianji_chaosong_xuanren (i) {
      console.log(i)
      i.zhi = !i.zhi
      if (i.zhi) {
        this.$set(i, 'name', i.staff_name)
        this.$set(i, 'ids', i.staff_id)
        this.$set(i, 'type', 2)
        this.chaosong_renyuan_list_xuan.push(i)
      } else {
        for (let a = 0; a < this.chaosong_renyuan_list_xuan.length; a++) {
          if (this.chaosong_renyuan_list_xuan[a].ids == i.staff_id) {
            this.chaosong_renyuan_list_xuan.splice(a, 1)
          }
        }
      }
      this.panduan_chaosong_quanxuan()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../style/salesman.scss';
.annualb{
    background:#fff;
    width: 96%;
    height:100%;
    position: relative;
    .tit{
        height:0.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.28rem;
        font-size: 0.18rem;
        color:#0F0F0F;
        border-bottom: 0.02rem solid #EEEEEE;
        div{
            height:0.6rem;
            font-size: 0.14rem;
            color:#262626;
            position: relative;
            cursor: pointer;
            p{
                line-height:0.6rem;
                display: flex;
                width: 0.9rem;
                align-items: center;
                justify-content: center;
                i{
                    font-size: 0.12rem;
                    margin-left: 0.1rem;
                }
                .el-icon-caret-bottom{
                    display: none;
                }
            }
            &:hover{
                .el-icon-caret-bottom{
                    display: block;
                }
                .el-icon-caret-left{
                    display: none;
                }
                ul{
                    display: block;
                }
            }
            ul{
                position: absolute;
                display: none;
                top:0.6rem;
                width: 0.9rem;
                height:1.2rem;
                box-shadow:0 0 0.1rem 0 rgba(243,243,246,0.8);
                z-index: 99;
                li{
                    line-height: 0.37rem;
                    text-align: center;
                    width: 0.9rem;
                    &:hover{
                        background: rgb(241, 240, 240);
                    }
                }
            }
        }
        // p{
        //     display: flex;
        //     align-items: center;
        //     font-size: 0.1rem;
        //     color:#4C4A4D;
        // }
    }
    .tit_2{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.38rem;
        padding: 0 0.3rem;
        ul{
            display: flex;
            align-items: center;
            li{
                padding: 0.07rem 0.41rem;
                font-size: 0.12rem;
                border:0.01rem solid #888888;
                color:#888888;
                cursor: pointer;
                &:nth-child(3){
                    border-radius: 0 0.02rem 0.02rem 0;
                }
                &:nth-child(1){
                    border-radius: 0.02rem 0 0 0.02rem;
                }
            }
        }
    }
    .shaixuan{
        // display: flex;
        // align-items: center;
        // font-size: 0.14rem;
        // color:#4C4A4D;
        position: absolute;
        right:0.6rem;
        font-size: 0.14rem;
        display: flex;
        align-items: center;
        p{
            height:0.24rem;
            width:0.68rem;
            line-height: 0.24rem;
            border:0.01rem solid #ccc;
            margin-left: 0.1rem;
            padding: 0 0.16rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
        }
    }
    .shili_tu{
        z-index: 99;
        width: 100%;
        height: 70%;
    }
    .shi_li{
        margin-top: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.96rem;
        li{
            display: flex;
            align-items: center;
            color:#A9A9A9;
            font-size: 0.12rem;
            span{
                display: inline-block;
                height:0.14rem;
                width:0.14rem;
                margin-right: 0.22rem;
                border-radius: 0.02rem;
            }
        }
    }
    .zhuanhuan{
        height:0.28rem;
        margin-top: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.6rem;
        border-top:0.01rem solid #eee;
        img{
          height: 0.18rem;
        }
      }
    .beijing{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.3);
        .box1{
            height:3.03rem;
            width:6.44rem;
            background:#fff;
            border-radius: 0.2rem;
            position: absolute;
            left:50%;
            margin-left: -3.22rem;
            top:50%;
            margin-top: -1.51rem;
            .tit_box{
                font-size: 0.18rem;
                color:#1A2533;
                text-align: center;
                padding-top:0.25rem;
                margin-bottom: 0.45rem;
            }
            .con{
                font-size: 0.14rem;
                color:#4C4A4D;
                text-align: center;
                margin-bottom: 0.2rem;
            }
            .con_box{
                display: flex;
                justify-content: center;
                font-size: 0.12rem;
                color:#4C4A4D;
                align-items: center;
            }
            .foot{
                display: flex;
                justify-content: flex-end;
                margin-right: 0.6rem;
                margin-top:0.64rem;
                span{
                    display: block;
                    height:0.43rem;
                    width:0.83rem;
                    line-height: 0.43rem;
                    border:0.01rem solid #979797;
                    text-align: center;
                    font-size: 0.14rem;
                    color:#888888;
                    border-radius: 0.02rem;
                    cursor: pointer;
                    &:nth-child(1){
                        background: #F16977;
                        border:0.01rem solid #F16977;
                        color:#fff;
                        margin-right:0.19rem;
                    }
                }
            }
        }
    }
}
</style>
